.wrapper {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  position: relative;
}

.hash {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100% !important;
  height: 100% !important;
  max-height: 80vh !important;
  position: absolute !important;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 4%,
    rgba(0, 0, 0, 0) 8%,
    rgba(0, 0, 0, 0) 92%,
    rgba(0, 0, 0, 0.2) 96%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.close {
  top: 48px;
  right: 50%;
  position: fixed;
  font-size: 1.2rem;
  width: max-content;
  transform: translate(50%, -50%);
}

.meme {
  object-fit: contain;
  height: 80vh !important;
}

.title {
  top: 12px;
  right: 12px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 2;
}

.title img {
  width: 15px;
  height: 15px;
}

.title span {
  font-size: 10px;
  color: var(--white);
}

.play {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share,
.bookmark,
.mute {
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  width: 28px;
  height: 28px;
}

.mute {
  z-index: 2;
  right: 1.25rem;
}

.vmute {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 12px;
  right: 12px;
}

@media screen and (min-width: 850px) {
  .share,
  .bookmark {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .title img {
    width: 12px;
    height: 12px;
  }

  .title span {
    font-size: 8px;
  }
}

@media screen and (max-width: 850px) {
  .share {
    left: 1.25rem;
  }

  .bookmark {
    left: calc(1.25rem + 28px + 1.25rem);
  }

  .wrapper {
    max-height: 75vh;
    height: auto !important;
    width: calc(100vw - 40px) !important;
    max-width: calc(100vw - 40px) !important;
  }

  .hash {
    max-height: 75vh !important;
  }

  .meme {
    height: 75vh !important;
    max-width: calc(100vw - 40px) !important;
  }

  .cross {
    position: fixed;
    height: 32px;
    width: 32px;
    top: 32px;
    left: 32px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper,
  .hash {
    max-height: 70vh !important;
  }

  .meme {
    height: 70vh !important;
  }
}

@media screen and (max-width: 500px) {
  .share,
  .bookmark,
  .mute {
    width: 24px;
    height: 24px;
  }

  .bookmark {
    left: calc(1.25rem + 24px + 1rem);
  }

  .wrapper,
  .hash {
    max-height: 65vh;
  }

  .meme {
    height: 65vh !important;
  }

  .cross,
  .vmute {
    scale: 0.9;
  }

  .close {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 400px) {
  .share,
  .bookmark,
  .mute {
    width: 20px;
    height: 20px;
  }

  .bookmark {
    left: calc(1.25rem + 20px + 1rem);
  }
}
