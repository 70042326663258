:root {
  --white: #ffffff;
  --blue-100: #cde7f9;
  --blue-200: #9fd2f4;
  --blue-400: #0d476e;
  --blue-500: #042337;
  --main-black: #050505;
  --main-grey: #3f4246;
  --main-yellow: #ebffaf;
  --deep-yellow: #d7eb9b;
}
