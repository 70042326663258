.wrapper {
  left: 0;
  bottom: 0;
  position: fixed;
}

.control {
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.36);
}

.open,
.close {
  gap: 15px;
  width: 100vw;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  padding: 15px;
}

.open {
  flex-direction: column;
}

.top,
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top {
  gap: 1rem;
}

.content {
  align-items: start;
  flex-direction: column;
  gap: 5px;
}

.logo {
  width: 45px;
}

.title {
  font-size: 0.9rem;
  font-weight: 700;
}

.text {
  color: var(--main-grey);
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.button {
  border: 0;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 24px;
  background: linear-gradient(var(--blue-400) 0%, var(--blue-500) 100%);
  font-size: medium;
}

.button:hover {
  background: linear-gradient(var(--blue-500) 0%, var(--blue-400) 100%);
}

.bottom {
  width: 100%;
}

.bottom .button {
  width: 100%;
  padding: 15px 30px;
}

.arr {
  width: 16px;
  height: 16px;
  margin: 6px 6px;
}

.arra {
  transform: translateY(-1px);
}

.arrd {
  transform: translateY(1px);
}

@media screen and (min-width: 450px) {
  .logo {
    width: 50px;
  }

  .top {
    gap: 20px;
  }

  .open {
    gap: 20px;
  }

  .title {
    font-size: 1.1rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1.35rem;
  }
}

@media screen and (min-width: 550px) {
  .title {
    font-size: 1.2rem;
  }

  .text {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width: 650px) {
  .close,
  .control {
    display: none;
  }

  .open {
    flex-direction: row;
  }

  .bottom,
  .bottom .button {
    width: max-content;
  }
}
