.wrapper {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  max-height: 80vh;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--main-yellow);
  padding: 24px 20px;
}

.text1 {
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}

.text2 {
  font-size: 0.8rem;
  color: var(--onyx-blue);
  transform: translateY(-5px);
  text-align: center;
}

.text3 {
  font-size: 0.8rem;
  font-weight: 700;
}

.text4 {
  font-size: 0.75rem;
  font-weight: 700;
}

.logo {
  height: 50px;
}

.card {
  height: 150px;
  border-radius: 12px;
  overflow: hidden;
  border: 2.426px solid rgba(0, 0, 0, 0.09);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--deep-yellow);
}

.big {
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  border: 0;
  color: var(--white);
  border-radius: 18px;
  background: linear-gradient(var(--blue-400) 0%, var(--blue-500) 100%);
}

.big:hover {
  background: linear-gradient(var(--blue-500) 0%, var(--blue-400) 100%);
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  width: 30px !important;
  height: 30px !important;
}

@media screen and (min-height: 1000px) {
  .wrapper {
    scale: 1.2;
    transform-origin: top left;
  }
}

@media screen and (min-height: 1400px) {
  .wrapper {
    scale: 1.4;
  }
}

@media screen and (min-width: 850px) {
  .big {
    scale: 0.8;
    margin: -4px 0;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    gap: 15px;
  }
}

@media screen and (min-width: 1400px) {
  .text1 {
    font-size: 1.4rem;
  }

  .text2 {
    font-size: 0.9rem;
  }

  .text3 {
    font-size: 0.9rem;
  }

  .text4 {
    font-size: 0.8rem;
  }

  .logo {
    height: 60px;
  }

  .card {
    height: 200px;
  }

  .buttons {
    padding-bottom: 2rem;
  }

  .icons {
    gap: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .text1 {
    font-size: 1rem;
  }

  .text2 {
    font-size: 0.75rem;
  }

  .text3 {
    font-size: 0.75rem;
  }

  .text4 {
    font-size: 0.7rem;
  }

  .logo {
    height: 40px;
  }

  .card {
    height: 120px;
  }

  .buttons {
    padding-bottom: 0.5rem;
  }

  .icons {
    gap: 5px;
  }

  .big {
    padding: 12px 24px;
  }

  .wrapper {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    width: 80vw;
    padding: 24px 20px;
  }

  .text1 {
    font-size: 1rem;
  }

  .text2 {
    font-size: 0.75rem;
  }

  .text4 {
    font-size: 0.7rem;
  }

  .card,
  .text3 {
    display: none;
  }

  .buttons {
    padding-bottom: 20px;
    margin-bottom: 8px;
  }

  .buttons a {
    width: 100%;
    margin-top: 8px;
  }

  .big {
    border-radius: 16px;
    font-size: 0.8rem;
    width: 100%;
  }

  .icons {
    gap: 16px;
  }
}
