.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: span 2;
  padding: 0 2rem;
  width: 100%;
}

.logos,
.icons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  height: 50px;
}

.title,
.icon {
  height: 40px;
}

.icons {
  display: none;
}

@media screen and (min-width: 1400px) {
  .wrapper {
    padding: 0 0 10px 3rem;
  }

  .title,
  .icon {
    height: 50px;
  }

  .logo {
    height: 60px;
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    height: 9vh;
    width: calc(100vw - 60px);
    padding: 0;
  }

  .title,
  .icon {
    height: 50px;
  }

  .logo {
    height: 60px;
  }

  .icons {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .logos {
    gap: 8px;
  }

  .title,
  .icon {
    height: 40px;
  }

  .logo {
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin-bottom: -1rem;
  }

  .title,
  .icon {
    height: 30px;
  }

  .logo {
    height: 40px;
  }
}
