* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "gt-maru", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-decoration: none;
  color: var(--vampire-black);
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 3rem 0;
  background: linear-gradient(180deg, var(--blue-100) 0%, var(--blue-200) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

/* MODAL CSS */

.ant-modal-root .ant-modal-centered .ant-modal {
  transform-origin: center !important;
  width: max-content !important;
}

.ant-modal .ant-modal-content {
  padding: 0;
  width: max-content;
  border-radius: 1.25rem;
}

/* IMAGE CSS */

.ant-image-preview-close {
  padding: 0;
}

.ant-image-preview-root .ant-image-preview-mask {
  background: rgba(0, 0, 0, 0.9);
}

.ant-image-preview-root .ant-image-preview-img {
  max-width: 90%;
}

/* MAIN CSS */

main {
  display: grid;
  gap: 24px;
  max-width: 1400px;
  justify-items: center;
  align-items: flex-start;
  grid-template-columns: 1.5fr 1fr;
  margin: 0 200px;
}

@media screen and (max-width: 850px) {
  main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 16px;
  }
}
