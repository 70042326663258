@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruLight.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruLightOblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruRegular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruRegularOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruMedium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GTMaruMediumOblique.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GT-Maru-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GT-Maru-Bold-Oblique.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GT-Maru-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gt-maru";
  src: url("../src/fonts/GT-Maru-Black-Oblique.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
